
import { Vue, Component, Prop } from "vue-property-decorator";
import gql from "graphql-tag";
import { orderBy } from "lodash";
import { getChildAssets, getFirstParentAsset, updateAssetProperties } from "@/config/asset";
import { DecoratedAsset, GqlAsset } from "@/types";
import OutdoorUnit from "./OutdoorUnit.vue";
import { deviceLock } from "@/gql/fragments/deviceLock";

@Component({
  components: {
    OutdoorUnit
  },
  apollo: {
    assetWithParentAndSiblings: {
      query: gql`
        ${deviceLock}
        query ParentAssetQuery($assetUuid: ID!) {
          assetWithParentAndSiblings: asset(assetUuid: $assetUuid) {
            parentLinks {
              linkType
              parentAsset {
                assetUuid
                knownAssetUuid
                name
                smart
                online
                serialNumber
                manufacturer {
                  name
                }
                assetModel {
                  name
                }
                ... on Device {
                  properties
                  settings
                  miscFields
                  thresholds {
                    values
                  }
                  ...DeviceLockFields
                }
                childLinks {
                  linkType
                  childAsset {
                    assetUuid
                    knownAssetUuid
                    name
                    smart
                    online
                    serialNumber
                    manufacturer {
                      name
                    }
                    assetModel {
                      name
                    }
                    ... on Device {
                      properties
                      settings
                      miscFields
                      thresholds {
                        values
                      }
                      ...DeviceLockFields
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          assetUuid: this.$route.params.assetUuid
        };
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    },
    $subscribe: {
      assetDataChanges: {
        query: gql`
          subscription AssetDataChanges($assetUuids: [ID!]!, $authorization: String!) {
            assetDataChanges(assetUuids: $assetUuids, authorization: $authorization) {
              assetUuid
              property
              stamp
              value
            }
          }
        `,
        variables() {
          return {
            assetUuids: this.subscribableAssetUuids,
            authorization: this.$store.getters.accessToken
          };
        },
        skip() {
          return this.subscribableAssetUuids.length === 0;
        },
        result({ data }: Record<string, any>) {
          if (this.assetWithParentAndSiblings && data.assetDataChanges) {
            updateAssetProperties(this.indoorUnits, data.assetDataChanges);
          }
        }
      }
    }
  }
})
export default class DeviceDashboardTab extends Vue {
  @Prop({ type: Object, required: true })
  readonly asset: DecoratedAsset;

  assetWithParentAndSiblings: GqlAsset | null = null;

  get outdoorUnit(): DecoratedAsset | null {
    if (this.assetWithParentAndSiblings === null) return null;
    return getFirstParentAsset(this.assetWithParentAndSiblings, { linkType: "VRF_OU_IU" });
  }

  get otherIndoorUnits(): DecoratedAsset[] {
    if (this.outdoorUnit === null) return [];

    const childAssets = getChildAssets(
      this.outdoorUnit,
      l => l.linkType === "VRF_OU_IU" && l.childAsset.assetUuid !== this.asset.assetUuid
    );
    return orderBy(childAssets, "properties.iu_unit_addr.value");
  }

  get indoorUnits(): DecoratedAsset[] {
    return orderBy([this.asset, ...this.otherIndoorUnits], "properties.iu_unit_addr.value");
  }

  get subscribableAssetUuids(): string[] {
    if (this.outdoorUnit === null) return [];

    const indoorUuids = this.otherIndoorUnits.map(a => a.assetUuid);
    return [this.outdoorUnit.assetUuid, ...indoorUuids];
  }
}
